<template>
  <div class="groups-access">
    <div class="groups-access__container" @click="Store.commit('accessSettings/setAccessUsersInModal',false)">
      <div class="groups-access__header">
        <h1 class="groups-access__header_text">
          {{ $t('objects.groups.access-title') }}
        </h1>
        <div class="close-btn cursor-pointer" @click="$emit('onBack')">
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="14" cy="14" r="14" fill="#EDEFF3" />
            <path
              d="M20 9.20857L18.7914 8L14 12.7914L9.20857 8L8 9.20857L12.7914 14L8 18.7914L9.20857 20L14 15.2086L18.7914 20L20 18.7914L15.2086 14L20 9.20857Z"
              fill="#292929"
            />
          </svg>
        </div>
      </div>
      <div class="objects__access-body-header">
        <div class="group__info">
          <div class="group__info-name">{{ groupInfo.name }}</div>
          <div class="group__info-count">({{ $t('access.settings.users.count') }} {{objectsDataProps.length}})</div>
        </div>
        <div class="group__search">
          <input
              class="input-inner"
              :placeholder="$t('search')"
              v-model="searchObject"
          />
          <UiIcon
              value="skif_search"
              height="20px"
              width="20px"
              class="group__search-icon"
          />
        </div>
        <div class="group__settings">
          <div class="group__settings-add" @click.stop="Store.commit('accessSettings/setAccessUsersInModal',true)" >
            <UiIcon
                value="add"
                width="16px"
                height="16px"
                color="#3A7CCA"
                class="mt-1"
            />
            <div class="add__name">Добавить пользователя</div>
          </div>
          <div
              v-if="Store.getters['accessSettings/getAccessUsersInModal']"
              class="users__items"
          >
            <AddUsers
                :included="rows"
                :users="getUsers"
                addUsersType="users"
                buttonName="Добавлен"
                @addUser="addUser"
            />
          </div>
        </div>
      </div>
      <div class="table__content">
        <table>
          <thead>
          <tr class="table__row">
            <th class="first__item">-</th>
            <th v-for="column in columns" :key="column.id">
              <div class="table__content_item">{{ column.name }}</div>
            </th>
          </tr>
          </thead>
          <tbody v-if="!loader" :key="keyColumn">
          <tr v-for="row in rows" v-if="row" :key="row.id">
            <td>
              <UserInfoForObjects
                  :isPopup="row.popup"
                  :isShowPopup="row.showPopup"
                  :row="row"
                  @deleteUser="removeUser"
                  @changeRole="changeRole"
                  :roleName="'Группы доступа'"
              />
            </td>
            <td
                v-for="column in columns"
                :key="column.id">
              <input
                  type="checkbox"
                  @change="(e) => handleCheckboxChange(row.id, column.name, e.target.checked, row)"
                  :checked="isActionActive(row.actions, column.id)"
                  :class="{ active: isActionActive(row.actions, column.id) }"
                  :disabled="disabledActions(row, column)"
              />
            </td>
          </tr>
          </tbody>
          <div class="loader__block">
            <UiLoader v-if="loader"/>
          </div>
        </table>
      </div>
      <div class="groups-access__footer">
        <el-button class="footer__cancel border-0" @click="$emit('onBack')">{{
          $t('new_units.footer.cancel')
        }}</el-button>
        <el-button
          class="footer__save border-0"
          @click="handler"
          >{{ $t('new_units.footer.save') }}</el-button
        >
      </div>
    </div>
  </div>
</template>
<script setup>
import {ref, defineEmits, onMounted, computed, onUnmounted} from 'vue'
import UiIcon from '@/components/companyControlsCenter/components/AccessSettings/ui/icon.vue'
import { useStore } from '@/store/store.js'
import UserInfoForObjects from "@/components/companyControlsCenter/components/AccessSettings/ui/userInfoForObjects";
import AddUsers from '@/components/companyControlsCenter/components/AccessSettings/addUsers.vue'
import UiLoader from "@/components/unitsNew/components/modalSensors/components/loader.vue";
import _,{debounce} from "lodash";

const emit = defineEmits(['onBack'])

const props = defineProps({
  groupName: {
    type: String,
    default: ''
  },
  update: {
    type: Boolean,
    default: false
  },
  groupInfo: {
    type: Object,
    default: () => {}
  }
})

const keyColumn = ref(0)
const searchObject = ref('')
const openSearch = ref(false)
let objectsDataProps = ref([]);
const usersData = ref([])
objectsDataProps = ref([])
const loader = ref(false)
const Store = useStore()

const getActionsItems = computed( () => {
  return  Store.getters['accessSettings/getAllActions']
})

const columns = ref(
    getActionsItems.value.filter((c) => {
      if (c !== null) {
        return c._unit_action
      }
    })
)

const rows = computed(() => {
  if (searchObject.value) {
    return objectsDataProps.value.filter((item) => {
      return item
          ? item.name
              .toLowerCase()
              .includes(searchObject.value.toLowerCase())
          : null
    })
  }
  return objectsDataProps.value
})

const getUsers = computed(() => {
  return Store.getters['users/getAllUsers'].map(val => {
    val.isActive = false
    return val
  }).filter(val=>val.company_user)
})

const handler=_.debounce(async function() {
  await handleSave()
}, 800, {
  loading: true,
  throttle: false,
  maxWait: 1200,
})

const removeUser =async (e) => {
  usersData.value=usersData.value.filter(el=>el.user.id!==e.id)
  await handleSave('remove')
}

const changeRole = async (item,row)=>{
  await Store.dispatch('accessSettings/changeUserRole', { id: row.id, role: {
      id: item.id,
      value: item.value,
      key: item.key
    }
  }).then( async () => {
   await initialize(props.groupInfo.id)
})
}

const allRoles = computed(() => {
  return Store.getters['accessSettings/getAllRoles']
})

const disabledActions = (row, column) => {
  const possibleActionsKeys = allRoles.value.filter(role=>role.id===row.role.id)[0].units_actions.map(action => action.key)
  if(possibleActionsKeys.includes(column.key)){
    return false
  }else{
    return true
  }
}

const isActionActive = (actions, columnId) => {
  if (!actions || actions.length === 0) {
    return false;
  }
  return actions.some(action => action.id === columnId);
}

const handleCheckboxChange = async (userId, actionName, isChecked, row) => {
  const userIndex = usersData.value.findIndex(u => u.user.id === userId);
  const possibleActions = allRoles.value.filter(role=>role.id===row.role.id)[0].units_actions
  if (userIndex !== -1) {
    const actionIndex = usersData.value[userIndex].actions.findIndex(a => a.name === actionName);
    if (actionIndex !== -1) {
      usersData.value[userIndex].actions[actionIndex].isActive = isChecked;
    }else{
      const actionOnAdd = possibleActions.filter(action=>{
        if(action.name === actionName){
          return action
        }
      })[0]
      actionOnAdd.isActive=true
      usersData.value[userIndex].actions.push(actionOnAdd)
    }
  }
}

const  addUser = async (newUser) => {
  const exists = rows.value.some(item => item.id === newUser.id);
  if (!exists) {
    const newActions = columns.value.map(c=>{
      return {
        id:c.id,
        name:c.name,
        key:c.key
      }
    })
    objectsDataProps.value.push(newUser)
    openSearch.value=false
    usersData.value.push({
      actions: newActions,
      user: newUser,
    })
  }
  loader.value=true
  await handleSave('add').then(()=>{
    loader.value=false
  })
}

const prepareDataForSaving = () => {
  const dataToSave = usersData.value.filter(user=>{
    if (user) return user
  })
      .map(user=>{
        if(user){
          return {
            user:user.user,
            actions:user.actions.filter(a=>{
              if(!Object.keys(a).includes('isActive')||(Object.keys(a).includes('isActive')&&a.isActive===true)){
                return a
              }
            })
          }
        }
      })
  return dataToSave;
}

const handleSave = async (action) => {
  const dataToSave = prepareDataForSaving();
  // loader.value = true;
  await Store.dispatch('accessSettings/saveRoleActions', {form: dataToSave, id: props.groupInfo.id})
      .then(async (res) => {
        objectsDataProps.value=res.map(r=>{
          return{
            actions:r.actions,
            available_actions_keys:r.available_actions_keys,
            ...r.user,
          }
        })
        if(!(action==='add' || action==='remove')){
          emit('onBack')
        }
        // loader.value = false;
      })

}
const initialize=async (id)=>{
  // loader.value=true
  if (props.groupInfo.id) {
    await Store.dispatch('accessSettings/getGroupsByObject', id).then(res => {
      objectsDataProps.value = res.map(r => {
        return {
          actions: r.actions,
          available_actions_keys: r.available_actions_keys,
          ...r.user,
        }
      })
      usersData.value = objectsDataProps.value.map(obj=>{
        const userActions =obj.actions.map(o=>{
          return {
            id:o.id,
            name:o.name,
            key:o.key
          }
        })
        return {
          actions:userActions,
          user:{
            id:obj.id,
            name:obj.name,
          }
        }
      })
      // loader.value=false
    })
  }
}

onMounted( async ()=> {
  loader.value=true
  await Store.dispatch('accessSettings/getAllRoles')
  await initialize(props.groupInfo.id)
  loader.value=false
})
onUnmounted(async ()=>{
  await Store.dispatch('units/GET_GROUP_UNITS')
  Store.commit('accessSettings/setAccessUsersInModal',false)
})
</script>

<style scoped lang="scss">
.groups-access {
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  width: 100%;
  height: 100%;
  padding-top: 80px;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 11199;
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    width: 100%;
    height: 90%;
    //max-height: calc(100vh - 150px);
    overflow: hidden;
    max-width: 70%;
    position: absolute;
    top: 20px;
    left: 268px;
    bottom: 0;
    background: #ffffff;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.06), 0 8px 40px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    padding-top: 18px;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
    padding: 0 20px;

    &_text {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 120%;
    }
  }
  .objects__access-body-header {
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .group__info {
      &-name {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        color: #292929;
      }

      &-count {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        color: #a6aeb8;
      }
    }

    .group__search {
      position: relative;

      &-icon {
        position: absolute;
        top: 8px;
        left: 8px;
      }
      .input-inner{
        width: 300px;
        padding: 8px 8px 6px 33px !important;
        height: 36px !important;
        border: 1px solid #DCDFE6;
        border-radius: 10px;
        background:  #EDEFF3;
        color: #A6AEB8;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
      .input-inner:focus{
        outline: none;
      }
    }

    .group__settings {
      display: flex;
      column-gap: 32px;

      &-add {
        display: flex;
        align-items: center;
        cursor: pointer;

        .add__name {
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 19px;
          color: #3a7cca;
        }
      }

      &-icons {
        display: flex;
        align-items: center;
        column-gap: 8px;

        .columns__icon {
          border-radius: 4px;
          width: 28px;
          height: 28px;
          background: #edeff3;
          padding: 4px;
        }

        .more__icon {
          border-radius: 4px;
          width: 28px;
          height: 28px;
          background: #edeff3;
          padding: 4px;

          &-radius {
            width: 20px;
            height: 20px;
            border: 1.5px solid black;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .users__items {
        position: absolute;
        top: 40px;
        right: 110px;
        z-index: 1000;
        overflow: hidden;
      }
    }
  }
  .table__content {
    position: relative;
    height: 75%;
    overflow-y: auto;

    table {
      width: 100%;

      thead {
        th {
          padding: 7px 12px 8px 12px;
          white-space: normal;
          font-size: 11px;
          text-align: center;
          font-weight: 400;
          line-height: 120%;
          border: 0.5px solid #cdd4dd;

          &:first-child {
            background: #ffffff;
            border-width: 0.5px;
            border-left: none;
            border-right: none;
            width: 211px;
          }
        }
      }

      tbody {
        td {
          text-align: center;
          background: #dbebff;
          border: 0.5px solid #cdd4dd;
          height: 32px;

          &:first-child {
            background: #ffffff;
            border-width: 0.5px;
            border-left: none;
            border-right: none;
          }
        }

        .active {
          background: #dbebff;
        }
      }
      .loader__block {
        width: 100%;
        position: absolute;
        top: 200px;
      }
    }
    .table__row {
      .table__content_item {
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 16px 20px 26px;
    margin-top: 16px;
    border-top: 1px solid $light-grey-tone-first-color;
    background: white;
    z-index: 11;
  }
}
.main {
  &__header {
    margin-top: 9px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    padding-bottom: 12px;
    border-bottom: 1px solid $light-grey-tone-first-color;

    &-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;
      color: $main-title-color;
      margin-left: 4px;
    }
  }
  &__checkboxes {
    max-width: 400px;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 386px);
    overflow-y: auto;
    overflow-x: hidden;

    :deep(.el-checkbox) {
      padding: 10px 0;
      border-bottom: 1px solid $light-grey-tone-first-color;
    }

    &_item {
      display: flex;
      align-items: center;
      width: 100%;
      min-width: 100%;
      padding: 10px 20px !important;
      margin: 0 !important;
      :deep(.el-checkbox__label) {
        width: 100%;
        max-width: 360px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

.footer {
  &__cancel,
  &__save {
    border-radius: 8px;
    outline: none;
    font-weight: 600;
  }

  &__cancel {
    width: 100%;
    background: #fff2ec;
    color: $red-bright-delight-color;
    max-width: 144px;

    &:hover {
      background: #ffe3d7;
      color: $red-bright-delight-color;
    }
  }

  &__save {
    width: 100%;
    background: $curios-blue-color;
    color: $white;
    max-width: 204px;

    &:hover {
      background: $blue-tone-third-color;
      color: $white;
    }

    &.is-disabled {
      background-color: $light-grey-tone-first-color;
    }
  }
}
:deep(.el-select__caret) {
  color: #3a7cca !important;
}
:deep(.el-select .el-input .el-select__caret.is-reverse) {
  color: #3a7cca !important;
  padding-left: 2px;
}
:deep(.is-checked .el-checkbox__inner) {
  border-color: transparent !important;
}
</style>
