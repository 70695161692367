<template>
  <el-tabs :value="activeName" class="object-tabs" @tab-click="changeTab">
    <el-tab-pane
      v-for="tab in tabs"
      :key="tab.name"
      :label="tab.label"
      :name="tab.name"
    >
      <el-badge slot="label" :value="tab.count">
        {{ tab.label }}
      </el-badge>
    </el-tab-pane>
  </el-tabs>
</template>
<script setup>
import { computed, ref } from 'vue'
import { createVuexHelpers } from 'vue2-helpers'
import sections from '@/enums/newUnits/main-sections.js'

import { useI18n } from '@/hooks/useI18n'
const { useGetters } = createVuexHelpers()

const { units, unitsGroup } = useGetters('units', ['units', 'unitsGroup'])
const { allTrailers } = useGetters('trailers', ['allTrailers'])
const { locators } = useGetters('locators', ['locators'])

const $t = useI18n()

const emits = defineEmits(['onChange'])
const props = defineProps(['activeName'])

const unitsCount = computed(() => units.value.length)
const unitsGroupCount = computed(() => unitsGroup.value.length)
const trailerCount = computed(() => allTrailers.value.length)
const linksCount = computed(() => locators.value.length)

const tabs = computed(() => {
  return [
    {
      label: $t(`new_units.tabs.${sections.OBJECTS}`),
      name: sections.OBJECTS,
      count: unitsCount.value
    },
    {
      label: $t(`new_units.tabs.${sections.GROUPS}`),
      name: sections.GROUPS,
      count: unitsGroupCount.value
    },
    {
      label: $t(`new_units.tabs.${sections.TRAILERS}`),
      name: sections.TRAILERS,
      count: trailerCount.value
    },
    {
      label: $t(`new_units.tabs.${sections.ACCESS_LINKS}`),
      name: sections.ACCESS_LINKS,
      count: linksCount.value
    }
  ]
})

const changeTab = (event) => {
  emits('onChange', event.name)
}
</script>

<style lang="scss">
@import '@/components/unitsNew/assets/styles/UnitsMainTabs.scss';
</style>
