<template>
  <div class="bg-white groups-container absolute top-0 left-0 bottom-0 z-30">
    <div class="header diveder flex justify-between items-center w-full">
      <h1 class="title">
        {{ getTitle }}
      </h1>
      <div>
        <!--        <InformationIcon class="cursor-pointer mr-3" />-->
        <BackIcon class="cursor-pointer" @click.native="$emit('onBack')" />
      </div>
    </div>
    <el-form :model="groupInfo" :ref="refs.groupsValidation">
      <div class="section diveder mt-4 flex justify-between items-center">
        <div class="input-container">
          <span class="input-label"> {{ $t('objects.name.group') }}: </span>
          <el-form-item :rules="rules.name" prop="name">
            <el-input
              v-model="groupInfo.name"
              @focus="$event.target.select()"
            />
          </el-form-item>
        </div>
        <div class="flex items-center cursor-pointer" v-if="props.title !== 'create'" >
          <UsersIcon />
          <p class="ml-2" @click="openAccessModal">
            {{ $t('objects.groups.access') }}
          </p>
        </div>
      </div>
    </el-form>
    <div class="table-section overflow-hidden">
      <div class="header my-4 flex justify-between items-center">
        <p>
          {{ $t('objects.groups.objects-in-group') }} ({{
            groupInfo.units.length
          }}):
        </p>
        <!-- TODO сделать сортировку -->
        <!-- <SortIcon class="ml-1 cursor-pointer" /> -->
      </div>
      <UnitsSearchInput
        v-model="searchValue"
        :search-model="searchModel"
        :options="objectsOptions"
        tab="objects"
        @onOptionChange="searchModel = $event"
        @onInput="searchValue = $event"
        @onSearch="searchObject"
      />
      <el-table
        :data="visibleUnits"
        class="groups-table h-full overflow-auto"
        stripe
        row-key="id"
        @select-all="selectionChange"
      >
        <el-table-column type="selection" min-width="10">
          <template slot-scope="scope">
            <UnitsCheckbox
              :value="groupsObjectsIds.includes(scope.row.id)"
              @onChange="changeSelect($event, scope.row)"
            />
          </template>
        </el-table-column>
        <el-table-column prop="name" label="Название" min-width="60" />
        <el-table-column prop="imei" align="left" label="IMEI" min-width="30" />
      </el-table>
    </div>
    <UnitsFooter
      class="mt-auto"
      @onCancel="$emit('onBack')"
      @onSubmit="submitObject"
    />
    <GroupsAccess
      v-if="isGroupAcess"
      :group-name="groupInfo.name"
      @onBack="isGroupAcess = false"
      :update="title === 'edit' || updateUser"
      :groupInfo="groupInfo"
    />
  </div>
</template>

<script setup>
import {
  ref,
  onBeforeMount,
  getCurrentInstance,
  onBeforeUnmount,
  computed, onMounted
} from 'vue'
import { useI18n } from '@/hooks/useI18n'
import { createVuexHelpers } from 'vue2-helpers'
import {useStore} from "@/store/store.js";

import { refs } from '@/components/unitsNew/helpers/index.js'

import BackIcon from '../assets/icons/back.vue'
import UsersIcon from '../assets/icons/users.vue'
import InformationIcon from '../assets/icons/information.vue'
import SortIcon from '../assets/icons/sort.vue'
import UnitsSearchInput from './UnitsSearchInput.vue'
import UnitsFooter from './UnitsFooter.vue'
import GroupsAccess from './GroupsAccess/Modal.vue'
import UnitsCheckbox from '@/components/unitsNew/components/UnitsCheckbox.vue'

import { objectsOptions } from '@/constants'

const { useGetters, useMutations, useActions } = createVuexHelpers()

const { units } = useGetters('units', ['units'])
const { RESET_SEARCH_UNITS } = useMutations('units', ['RESET_SEARCH_UNITS'])
const { CREATE_UNITGROUP, UPDATE_UNITGROUP, SEARCH_UNIT } = useActions(
  'units',
  ['CREATE_UNITGROUP', 'UPDATE_UNITGROUP', 'SEARCH_UNIT']
)

const $t = useI18n()
const instance = getCurrentInstance()
const Store = useStore()
const emits = defineEmits(['onCreate', 'onEdit'])

const props = defineProps({
  title: {
    type: String,
    default: 'create'
  },
  activeGroup: {
    type: Object,
    default: () => ({})
  }
})

const groupInfo = ref({
  units: []
})

const rules = ref({
  name: [{ required: true, message: $t('new_units.groups.errors.name') }]
})

const isGroupAcess = ref(false)
const updateUser = ref(false)
const groupsObjectsIds = ref([])
const searchValue = ref('')
const searchModel = ref(objectsOptions[0].value)

const visibleUnits = ref(units.value)

const submitObject = () => {
  refs.groupsValidation.value.validate(async (valid) => {
    if (valid) {
      props.title === 'create' ? createGroup() : editGroup()
    } else {
      instance.proxy.$showError($t('new_units.groups.errors.validation'))
    }
  })
}

const createGroup = async () => {
  try {
    await CREATE_UNITGROUP(groupInfo.value)

    emits('onCreate')
  } catch (err) {
    // instance.proxy.$showError($t('new_units.groups.errors.create'))
    instance.proxy.$showError($t(err))
  }
}

const editGroup = async () => {
  try {
    await UPDATE_UNITGROUP(groupInfo.value)

    emits('onEdit')
  } catch (err) {
    // instance.proxy.$showError($t('new_units.groups.errors.edit'))
    instance.proxy.$showError($t(err))
  }
}

const getGroupInfo = () => {
  groupInfo.value = JSON.parse(JSON.stringify(props.activeGroup))

  groupsObjectsIds.value = groupInfo.value.units.map((item) => item.id)

  units.value.sort((a, b) => {
    return (
      groupsObjectsIds.value.includes(b.id) -
      groupsObjectsIds.value.includes(a.id)
    )
  })
}

// const saveUsers = ({ users, id }) => {
//   isGroupAcess.value = false
//   updateUser.value = true
//   groupInfo.value.users = users
//   if (id) {
//     groupInfo.value.id = id
//   }
// }

const selectionChange = (units) => {
  groupInfo.value.units = units
  groupsObjectsIds.value = units.map((item) => item.id)
}

const changeSelect = (value, row) => {
  if (value) {
    groupInfo.value.units.push(row)
    groupsObjectsIds.value.push(row.id)
  } else {
    groupInfo.value.units = groupInfo.value.units.filter(
      (item) => item.id !== row.id
    )
    groupsObjectsIds.value = groupsObjectsIds.value.filter(
      (id) => id !== row.id
    )
  }
}

const searchObject = async () => {
  try {
    const formData = {
      conditions: [
        {
          field: searchModel.value,
          value: searchValue.value
        }
      ],
      fields: ['id', 'name', 'imei']
    }

    const searched = await SEARCH_UNIT({ formData })

    visibleUnits.value = searched
  } catch (err) {
    throw new Error(err.response.data.message)
  }
}

const openAccessModal = () => {
  refs.groupsValidation.value.validate(async (valid) => {
    if (valid || groupInfo.name) {
      // Store.commit('accessSettings/setIsAccessSettings', true)
      isGroupAcess.value = true
    } else {
      instance.proxy.$showError($t('new_units.groups.errors.validation'))
    }
  })
}

const getTitle = computed(() => {
  return props.title === 'create'
    ? $t('objects.create.new.group')
    : $t('objects.create.edit.group')
})

onBeforeMount(() => {
  if (props.title === 'edit') {
    getGroupInfo()
  }
})

onMounted(async()=>{
  await Store.dispatch('accessSettings/getAllActions')
})

onBeforeUnmount(() => {
  RESET_SEARCH_UNITS()
})
</script>

<style lang="scss">
@import '@/components/unitsNew/assets/styles/GroupsShowPage.scss';
</style>
