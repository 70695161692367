<template>
  <div>
    <el-radio-group @change="changeType" v-model="currentType">
      <div class="wrapper" v-for="(item, idx) in sensor_copy_type" :key="idx">
        <div class="sensors-item">
          <div class="sensors-item__radio">
            <el-radio :label="item.key" :id="`sensor_selected_${item.key}`"></el-radio>
          </div>
          <div class="sensors-item__info" @click="selectCopyType(item.key)" >
            <div class="name">
              {{ item.value }}
            </div>
            <div class="description">
              {{ sensorDescriptions[item.key] }}
            </div>
          </div>
        </div>
      </div>
    </el-radio-group>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'


export default {
  name: 'SensorType',
  data() {
    return {
      currentType: 'sensor_copy_type_insert',
      sensorDescriptions: {
        sensor_copy_type_merge: this.$t('objectsSensors.copy.text1'),
        sensor_copy_type_replace: this.$t('objectsSensors.copy.text2'),
        sensor_copy_type_insert: this.$t('objectsSensors.copy.text3')
      }
    }
  },
  computed: {
    ...mapGetters('dictionary', ['sensor_copy_type']),
  },
  methods: {
    ...mapMutations('units', ['setSensorCopyType']),

    changeType() {
      this.setSensorCopyType(this.currentType)
    },

    selectCopyType(copyType) {
      document.getElementById(`sensor_selected_${copyType}`)?.click()
    }
  },
  mounted() {
    console.log('this.setSensorCopyType(this.currentType)');
    this.setSensorCopyType(this.currentType);
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
  gap: 0.5em;
  .sensors-item {
    display: flex;
    margin: 10px 20px;
    padding: 10px;
    border-radius: 12px;
    width: 360px;
    background-color: #f6f7fa;
    &:hover {
      background-color: #edeff3;
    }
    &__radio {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 2px;
      margin-right: 8px;
      margin-bottom: 20px;
      :deep(.el-radio__label) {
        display: none;
      }
    }
    &__info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .name {
        font-size: 14px;
        font-weight: 600;
      }
      .description {
        font-weight: 400;
        font-size: 12px;
        color: #828d9b;
        margin-top: 6px;
      }
    }
  }
}
</style>
