<template>
  <el-form
    v-loading="loading"
    :model="trailer"
    :ref="refs.trailersValidation"
    class="object-trailers__details overflow-auto w-5/12 p-5"
  >
    <div class="object-trailers__details__main-fields flex flex-col gap-4 mb-5">
      <div class="relative">
        <span class="object-trailers__details__label input-label">
          {{ $t('new_units.trailers.fields.name') }}
        </span>
        <el-form-item :rules="rules.name" prop="name">
          <el-input v-model="trailer.name" />
        </el-form-item>
      </div>
      <div class="relative">
        <span class="object-trailers__details__label input-label">{{
          $t('new_units.trailers.fields.code')
        }}</span>
        <el-input v-model="trailer.code" @focus="$event.target.select()" />
      </div>
      <div class="relative">
        <span class="object-trailers__details__label input-label">{{
          $t('new_units.trailers.fields.length')
        }}</span>
        <el-input
          v-model="trailer.width"
          type="number"
          @focus="$event.target.select()"
        />
      </div>
      <div class="relative">
        <span class="object-trailers__details__label input-label">{{
          $t('new_units.trailers.fields.description')
        }}</span>
        <el-input v-model="trailer.description" type="textarea" rows="4" />
      </div>
    </div>
    <div
      class="object-trailers__details__additional-fields flex flex-col gap-4"
    >
      <span class="object-trailers__details__title">{{
        $t('new_units.trailers.additionalFields')
      }}</span>

      <el-form :model="model" :ref="refs.trailersFieldsValidation">
        <div
          class="flex flex-col gap-4 overflow-auto object-trailers__details__additional-fields__block"
        >
          <div
            v-for="field in trailer.custom_fields"
            :key="field.localId"
            class="flex justify-between items-center object-trailers__details__additional-fields__items gap-3"
          >
            <div class="flex flex-col gap-4">
              <div class="relative">
                <span class="object-trailers__details__label input-label">{{
                  $t('new_units.trailers.fields.name')
                }}</span>
                <el-form-item
                  :rules="rules.custom_field"
                  :prop="`name_${field.localId}`"
                >
                  <el-input v-model="field.name" />
                </el-form-item>
              </div>
              <div class="relative">
                <span class="object-trailers__details__label input-label">{{
                  $t('new_units.trailers.fields.value')
                }}</span>
                <el-form-item
                  :rules="rules.custom_field"
                  :prop="`value_${field.localId}`"
                >
                  <el-input v-model="field.value" />
                </el-form-item>
              </div>
            </div>
            <div
              class="object-trailers__details__additional-fields__remove cursor-pointer"
              @click="$emit('onDeleteField', field.localId)"
            >
              <DeleteIcon />
            </div>
          </div>
        </div>
      </el-form>
      <UnitsAddButton
        active-tab="add_field"
        class="object-trailers__details__additional-fields__add"
        @click.native="$emit('onAddField')"
      />
    </div>
    <el-button
      class="object-trailers__details__save border-0 w-full text-white p-0 mt-5 rounded-md"
      @click="$emit('onSave')"
      >{{ $t('new_units.trailers.save') }}</el-button
    >
  </el-form>
</template>

<script setup>
import { ref, watch } from 'vue'

import { refs } from '@/components/unitsNew/helpers/index.js'
import { useI18n } from '@/hooks/useI18n'

import DeleteIcon from '@/components/unitsNew/assets/icons/delete.vue'
import UnitsAddButton from './UnitsAddButton.vue'

const $t = useI18n()

const props = defineProps({
  trailer: {
    type: Object,
    default: () => ({})
  },
  loading: {
    type: Boolean,
    default: false
  }
})

const validateField = (rule, value, callback) => {
  const type = rule.field.split('_')[0]
  const localId = rule.field.split('_')[1]

  const currentField = props.trailer.custom_fields.find(
    (item) => item.localId === +localId
  )

  if (currentField[type]) {
    callback()
  } else {
    callback(new Error())
  }
}

const rules = ref({
  name: [{ required: true, message: $t('new_units.trailers.errors.name') }],
  custom_field: [
    { validator: validateField, message: $t('new_units.trailers.errors.name') }
  ]
})

const model = ref({})

const getPropValues = () => {
  const fields = props.trailer.custom_fields

  if (fields) {
    fields.forEach((field) => {
      model.value[`name_${field.localId}`] = field.name
      model.value[`value_${field.localId}`] = field.value
    })
  }
}

watch(
  () => props.trailer.custom_fields,
  () => {
    getPropValues()
  }
)
</script>

<style lang="scss">
@import '@/components/unitsNew/assets/styles/UnitsTrailersDetails.scss';
</style>
